import React from 'react';
import {DateInputElement} from 'types/documentTemplateElement';
import RadioInputHorizontal from "../../inputs/RadioInputHorizontal";
import TextInput from "../../inputs/TextInput";

interface DateInputPropertiesProps {
    element: DateInputElement;
    updateElement: (updatedElement: DateInputElement) => void;
}

const DateInputProperties: React.FC<DateInputPropertiesProps> = ({
                                                                     element,
                                                                     updateElement,
                                                                 }) => {
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const {id, value, type} = e.target;
        updateElement({
            ...element,
            properties: {
                ...element.properties,
                [id]: value
            },
        });
    };

    return (
        <div className="space-y-5 w-full">
            <RadioInputHorizontal
                label="Label"
                options={[
                    {id: 'show-label-yes', label: 'Yes', value: true},
                    {id: 'show-label-no', label: 'No', value: false},
                ]}
                selectedValue={element.properties.showLabel}
                onChange={(value) => updateElement({...element, properties: {...element.properties, showLabel: value}})}
            />
            {element.properties.showLabel && (
                <TextInput
                    id="label"
                    type="input"
                    label="Label Date"
                    value={element.properties.label}
                    onChange={handleChange}
                />
            )}

            <RadioInputHorizontal
                label="Allow Past Dates"
                options={[
                    {id: 'allow-past-dates-yes', label: 'Yes', value: true},
                    {id: 'allow-past-dates-no', label: 'No', value: false},
                ]}
                selectedValue={element.properties.allowPastDates}
                onChange={(value) => updateElement({...element, properties: {...element.properties, allowPastDates: value}})}
            />
            <RadioInputHorizontal
                label="Required"
                options={[
                    {id: 'required-yes', label: 'Yes', value: true},
                    {id: 'required-no', label: 'No', value: false},
                ]}
                selectedValue={element.properties.required}
                onChange={(value) => updateElement({...element, properties: {...element.properties, required: value}})}
            />
        </div>
    );
};

export default DateInputProperties;
