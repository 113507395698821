import React from 'react';
import {
    DateInputElement,
    DocumentTemplateElement,
    MultiLineTextInputElement,
    TextInputElement,
    YesNoInputElement,
    PhoneNumberInputElement,
    DropdownElement,
    SsnInputElement,
    EmailInputElement,
    AddressInputElement,
    CurrencyInputElement,
    DateTimeInputElement,
    TimeInputElement,
    RatingInputElement,
    SignatureInputElement,
    UrlInputElement,
    TableInputElement
} from 'types/documentTemplateElement';

import TextInputProperties from './properties/TextInputProperties';
import MultiLineTextInputProperties from './properties/MultiLineTextInputProperties';
import DropdownProperties from './properties/DropdownProperties';
import PhoneNumberInputProperties from './properties/PhoneNumberInputProperties';
import YesNoInputProperties from './properties/YesNoInputProperties';
import DateInputProperties from './properties/DateInputProperties';
import EmailInputProperties from './properties/EmailInputProperties';
import AddressInputProperties from './properties/AddressInputProperties';
import CurrencyInputProperties from './properties/CurrencyInputProperties';
import DateTimeInputProperties from './properties/DateTimeInputProperties';
import TimeInputProperties from './properties/TimeInputProperties';
import RatingInputProperties from './properties/RatingInputProperties';
import SignatureInputProperties from './properties/SignatureInputProperties';
import UrlInputProperties from './properties/UrlInputProperties';
import SsnInputProperties from "./properties/SsnInputProperties";
import TableInputProperties from "./properties/TableInputProperties";


interface RenderElementPropertiesProps {
    element: DocumentTemplateElement;
    updateElement: (updatedElement: DocumentTemplateElement) => void;
}

const RenderElementProperties: React.FC<RenderElementPropertiesProps> = ({
                                                                             element,
                                                                             updateElement,
                                                                         }) => {
    switch (element.elementType) {
        case 'TextInput':
            return (
                <TextInputProperties
                    element={element as TextInputElement}
                    updateElement={updateElement}
                />
            );
        case 'MultiLineTextInput':
            return (
                <MultiLineTextInputProperties
                    element={element as MultiLineTextInputElement}
                    updateElement={updateElement}
                />
            );
        case 'Dropdown':
            return (
                <DropdownProperties
                    element={element as DropdownElement}
                    updateElement={updateElement}
                />
            );
        case 'PhoneNumberInput':
            return (
                <PhoneNumberInputProperties
                    element={element as PhoneNumberInputElement}
                    updateElement={updateElement}
                />
            );
        case 'YesNoInput':
            return (
                <YesNoInputProperties
                    element={element as YesNoInputElement}
                    updateElement={updateElement}
                />
            );
        case 'SsnInput':
            return (
                <SsnInputProperties
                    element={element as SsnInputElement}
                    updateElement={updateElement}
                />
            );
        case 'DateInput':
            return (
                <DateInputProperties
                    element={element as DateInputElement}
                    updateElement={updateElement}
                />
            );
        case 'EmailInput':
            return (
                <EmailInputProperties
                    element={element as EmailInputElement}
                    updateElement={updateElement}
                />
            );
        case 'AddressInput':
            return (
                <AddressInputProperties
                    element={element as AddressInputElement}
                    updateElement={updateElement}
                />
            );
        case 'CurrencyInput':
            return (
                <CurrencyInputProperties
                    element={element as CurrencyInputElement}
                    updateElement={updateElement}
                />
            );
        case 'DateTimeInput':
            return (
                <DateTimeInputProperties
                    element={element as DateTimeInputElement}
                    updateElement={updateElement}
                />
            );
        case 'TimeInput':
            return (
                <TimeInputProperties
                    element={element as TimeInputElement}
                    updateElement={updateElement}
                />
            );
        case 'RatingInput':
            return (
                <RatingInputProperties
                    element={element as RatingInputElement}
                    updateElement={updateElement}
                />
            );
        case 'SignatureInput':
            return (
                <SignatureInputProperties
                    element={element as SignatureInputElement}
                    updateElement={updateElement}
                />
            );
        case 'UrlInput':
            return (
                <UrlInputProperties
                    element={element as UrlInputElement}
                    updateElement={updateElement}
                />
            );
        case 'TableInput':
            return (
                <TableInputProperties
                    element={element as TableInputElement}
                    updateElement={updateElement}
                />
            );
        default:
            return (
                <p className="text-slate-400 text-sm font-medium">
                    This element doesn't have any properties
                </p>
            );
    }
};

export default RenderElementProperties;
