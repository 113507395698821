import React, {useState} from "react";
import {useDispatch} from "react-redux";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import {AppDispatch} from "store/store";
import WhiteButton from "../components/WhiteButton";
import XIcon from "../assets/images/icons/XIcon";
import DownloadIcon from "../assets/images/icons/DownloadIcon";
import "@cyntler/react-doc-viewer/dist/index.css";

interface UseAttachmentModalProps<T> {
  openDrawer: () => void;
  fetchItemThunk: (id: string) => any;
}

const useAttachmentModal = <T,>({
  openDrawer,
  fetchItemThunk,
}: UseAttachmentModalProps<T>) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [label, setLabel] = useState<string>("");
  const [selectedAttachmentUrl, setSelectedAttachmentUrl] = useState<
    string | null
  >(null);
  const dispatch = useDispatch<AppDispatch>();

  const openModal = (attachmentUrl: string, label: string) => {
    setSelectedAttachmentUrl(attachmentUrl);
    setLabel(label);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAttachmentUrl(null);
    setLabel("");
  };
  const handleDownload = (attachmentUrl: string) => {
    if (attachmentUrl) {
      window.open(attachmentUrl, "_blank");
    }
  };

  const handleNameClick = async (
    item: T & {
      id: string;
      status: string;
      attachment?: any;
      name?: string;
    },
  ) => {
    if (item.status === "Complete" || item.attachment?.url != null) {
      if (item.attachment?.url) {
        openModal(item.attachment?.url, item.name || "");
      } else {
        alert("No attachment available for this item.");
      }
    } else {
      await dispatch(fetchItemThunk(item.id));
      openDrawer();
    }
  };

  const AttachmentModal = () => {
    if (!isModalOpen) return null;

    return (
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        onClick={closeModal}>
        <div
          className="relative bg-white rounded-xl w-4/6 py-6"
          onClick={e => e.stopPropagation()}>
          <div className="flex justify-between items-start gap-2 px-4">
            <div className="flex items-center gap-4">
              <div className="text-slate-600 text-xl font-semibold font-['Inter'] tracking-tight">
                {label}
              </div>

              <WhiteButton
                onClick={() => handleDownload(selectedAttachmentUrl || "")}
                icon={<DownloadIcon />}
              />
            </div>
            <button
              onClick={closeModal}
              className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
              <XIcon />
            </button>
          </div>
          <div className="p-4 h-full overflow-auto">
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={[{uri: selectedAttachmentUrl || ""}]}
              style={{height: "80vh"}}
              config={{
                header: {
                  disableHeader: false,
                  disableFileName: true,
                  retainURLParams: false,
                },
                csvDelimiter: ",", // "," as default,
                pdfVerticalScrollByDefault: true, // false as default
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return {
    openModal,
    closeModal,
    AttachmentModal,
    handleNameClick,
  };
};

export default useAttachmentModal;
