import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import SubmitButton from "components/SubmitButton";
import {setIsEditing} from "features/credential/credentialSlice";
import DescriptionInput from "components/inputs/DescriptionInput";
import {UserCredentialThunks} from "features/userCredential/userCredentialThunks";
import {UserCredential} from "types/userCredential";
import DateInput from "components/inputs/DateInput";
import FileInput from "components/inputs/FileInput";
import CustomSelect from "components/inputs/CustomSelect";
import {Credential} from "types/credential";
import {CredentialThunks} from "features/credential/credentialThunks";
import XIcon from "assets/images/icons/XIcon";
import FileDetailBox from "components/FileDetailBox";

interface UserCredentialFormProps {
    id?: string;
    closeDrawer: () => void;
    isDrawerOpen: boolean;
}

const UserCredentialForm: React.FC<UserCredentialFormProps> = ({id, closeDrawer, isDrawerOpen}) => {
    const userCredential = useSelector((state: RootState) => state.userCredential.userCredential);
    const credentials = useSelector((state: RootState) => state.credential.credentials);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedUserCredential, setUpdatedUserCredential] = useState(userCredential);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null); // New state for file
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!isLoadingFile) return;

        const loadingDuration = 2000;
        const timer = setTimeout(() => {
            setIsLoadingFile(false);
        }, loadingDuration);

        return () => clearTimeout(timer);
    }, [isLoadingFile]);

    useEffect(() => {
        const isFormValid =
            updatedUserCredential?.licensingAuthority?.trim() &&
            updatedUserCredential?.expirationDate?.trim() &&
            updatedUserCredential?.remindUserAt?.trim() &&
            updatedUserCredential?.credentialId &&
            (uploadedFile !== null || userCredential?.attachmentUrl) && !isLoadingFile;


        setIsSaveDisabled(!isFormValid);
    }, [updatedUserCredential, uploadedFile, isLoadingFile]);

    useEffect(() => {
        setUpdatedUserCredential(userCredential);
        setIsSaveDisabled(!userCredential?.name);
    }, [userCredential]);

    useEffect(() => {
        dispatch(CredentialThunks.index());
    }, [dispatch]);

    useEffect(() => {
        if (!isDrawerOpen)
            handleClose()
    }, [isDrawerOpen]);

    const handleFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const attachment = files[0];
            setUploadedFile(attachment);
            setUpdatedUserCredential((prevState: UserCredential) => ({
                ...prevState,
                attachment: attachment,
            }));
            setIsLoadingFile(true);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedUserCredential({...updatedUserCredential, [id]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const userCredential = new UserCredential(updatedUserCredential);
        setIsSubmitting(true);
        setIsSaveDisabled(true)
        if (userCredential?.id) {
            await dispatch(UserCredentialThunks.update(new UserCredential(updatedUserCredential))).then(() => closeDrawer());
        } else {
            await dispatch(UserCredentialThunks.create(new UserCredential(updatedUserCredential))).then(() => closeDrawer());
        }
    };
    const handleSelectChange = (value: string) => {
        setUpdatedUserCredential((prevState: Credential) => ({
            ...prevState,
            credentialId: value,
        }));
        setIsSaveDisabled(!value); //
    };


    const handleClose = () => {
        dispatch(setIsEditing(false));
        setUpdatedUserCredential({
            id: '',
            name: '',
            licensingAuthority: '',
            expirationDate: '',
            remindUserAt: '',
            credentialId: '',
            userId: '',
        })

        setUploadedFile(null);
        setIsSaveDisabled(true);
        setIsSubmitting(false);
    };

    const handleDelete = () => {
        setUploadedFile(null);
        setUpdatedUserCredential((prevState: UserCredential) => ({
            ...prevState,
            attachment: null,
            deleteAttachment: true,
            attachmentUrl: null
        }));
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            {userCredential?.id ? 'Edit certifications' : 'Create new certifications'}
                        </div>

                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {userCredential?.id ? 'Modify an employee\'s certifications so you can keep track of their status and remain compliant.' : 'Add an employee\'s certifications so you can keep track of their status and remain compliant.'}
                        </div>
                    </div>
                    <button onClick={closeDrawer} className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                        <XIcon/>
                    </button>
                </div>
                <div className="h-full px-6 pb-6 ">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col space-y-6 items-center mt-7">
                                <div className="w-full ">
                                    <FileInput
                                        onFileChange={handleFileChange}
                                        label="Drop your files here or"
                                        labelBlue="browse"
                                        subtitle="Maximum size: 50MB"
                                        disabled={isLoadingFile}
                                    />
                                </div>
                                {uploadedFile && (
                                    <div className="w-full">
                                        <FileDetailBox uploadedFile={uploadedFile}
                                                       handleDelete={handleDelete} isLoading={isLoadingFile}
                                                       loadingTime={2000}/>
                                    </div>
                                )}
                                {userCredential?.attachment?.url && uploadedFile === null && updatedUserCredential.attachment?.url !== null && (
                                    <div className="w-full">
                                        <FileDetailBox uploadedFile={userCredential?.attachment}
                                                       handleDelete={handleDelete}
                                                       fileCurrent={true}
                                        />
                                    </div>

                                )}
                                {userCredential?.previousAttachments && userCredential?.previousAttachments.map((file: any, index: number) => (
                                    <div className="w-full">
                                        <FileDetailBox uploadedFile={file}
                                                       handleDelete={handleDelete}
                                                       fileCurrent={false}
                                        />
                                    </div>
                                ))
                                }

                            <div className="w-full pt-2">
                                <div className="flex flex-col gap-[0.625rem] w-full">
                                    <label htmlFor="roleId"
                                           className="w-[487px] block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                        Certification
                                    </label>
                                    <CustomSelect
                                        value={updatedUserCredential?.credentialId}
                                        onChange={(selectedOption) => handleSelectChange(selectedOption.value)}
                                        options={credentials.map((credential: Credential) => ({
                                            value: credential.id,
                                            label: credential.name,
                                        }))}

                                    />
                                </div>
                            </div>
                            <div className="w-full">
                                <TextInput
                                    id="licensingAuthority"
                                    type="text"
                                    label="Licensing authority"
                                    value={updatedUserCredential?.licensingAuthority}
                                    placeholder=""
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-4 w-full ">
                                <DateInput
                                    id="expirationDate"
                                    label="Expiration date"
                                    type="date"
                                    value={updatedUserCredential?.expirationDate}
                                    onChange={handleChange}
                                    placeholder="MM/DD/YYYY"
                                />
                                <DateInput
                                    id="remindUserAt"
                                    label="Reminder"
                                    type="date"
                                    value={updatedUserCredential?.remindUserAt}
                                    onChange={handleChange}
                                    placeholder="MM/DD/YYYY"
                                    calendarAlignment={"left"}
                                />
                            </div>
                            <div className="w-full">
                                <DescriptionInput
                                    id="notes"
                                    type="text"
                                    label="Notes"
                                    value={updatedUserCredential?.notes}
                                    placeholder=""
                                    onChange={handleChange}
                                    optional="optional"
                                />
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      label={`${isSubmitting ? 'Uploading' : 'Upload'}`}
                                      disabled={isSaveDisabled || isSubmitting}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={closeDrawer}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default UserCredentialForm;
