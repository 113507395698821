import {useEffect, useRef, useState} from "react";

interface UsePinInputProps {
  initialPinLength?: number;
  pinPrefix?: string;
}

const usePinInput = ({
  initialPinLength = 4,
  pinPrefix = "",
}: UsePinInputProps) => {
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [pin, setPin] = useState(new Array(initialPinLength).fill(""));
  const [error, setError] = useState<string | null>(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handlePinInputEvent = (
    method: "on",
    handler: (inputValue: string, index: number) => void,
  ) => {
    pin.forEach((value, index) => {
      const el = window.HSPinInput.getInstance(
        `#${pinPrefix}pin-input-${index}`,
      );
      if (el) {
        el[method]("change", ({inputValue}: {inputValue: string}) =>
          handler(inputValue, index),
        );
      }
    });
  };

  useEffect(() => {
    handlePinInputEvent("on", handlePinChange);
  });

  useEffect(() => {
    const fullPin = pin.join("");
    setIsFormValid(fullPin.length === initialPinLength);
  }, [pin]);

  const handlePinChange = (value: string, index: number) => {
    if (isNaN(Number(value))) return;

    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (value !== "") {
      if (index < initialPinLength - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }

    setPin([...pin.map((d, idx) => (idx === index ? value : d))]);

    const nextElement = document.querySelector(
      `#${pinPrefix}pin-input-${index + 1}`,
    );
    if (nextElement && value) {
      (nextElement as HTMLInputElement).focus();
    }
    setError(null);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (
      e.key === "Backspace" &&
      !pin[index] &&
      e.currentTarget.previousSibling
    ) {
      setPin([...pin.map((d, idx) => (idx === index ? "" : d))]);
      (e.currentTarget.previousSibling as HTMLInputElement).focus();
    } else if (e.key === "ArrowLeft" && e.currentTarget.previousSibling) {
      (e.currentTarget.previousSibling as HTMLInputElement).focus();
    } else if (e.key === "ArrowRight" && e.currentTarget.nextSibling) {
      (e.currentTarget.nextSibling as HTMLInputElement).focus();
    } else if (e.key === "Backspace" && !pin[index]) {
      (e.currentTarget as HTMLInputElement).focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData("Text")
      .slice(0, initialPinLength);
    setPin(
      pastedData
        .split("")
        .map((char, index) => (index < initialPinLength ? char : "")),
    );
  };

  return {
    firstInputRef,
    pin,
    setPin,
    error,
    isFormValid,
    handlePinChange,
    handleKeyDown,
    handlePaste,
    setError,
  };
};

export default usePinInput;
