import React, {useEffect, useState} from 'react';
import SetupPassword from "features/auth/components/SetupPassword";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {useNavigate, useParams} from "react-router-dom";
import {showToast} from "components/ToastContainer";
import {UserInvitationThunks} from "features/userInvitation/userInvitationThunk";
import LoadingSpinner from "components/LoadingSpinner";
import TextInput from "components/inputs/TextInput";
import {setPassword} from "features/auth/authSlice";
import SubmitButton from "components/SubmitButton";
import ArrowRightIcon from "assets/images/icons/ArrowRightIcon";
import {CredentialThunks} from "features/credential/credentialThunks";

const AcceptInvitationPage: React.FC = () => {
    const invitation = useSelector((state: RootState) => state.userInvitation.invitation);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const params = useParams();
    const [isFormValid, setIsFormValid] = useState(false);
    const status = useSelector((state: RootState) => state.auth.status);
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
    });

    useEffect(() => {
        setIsFormValid(formData.password !== '' && formData.confirmPassword !== '' && formData.password === formData.confirmPassword);
    }, [formData]);

    useEffect(() => {
        const fetchInvitation = async () => {
            const token = params.token;
            if (!token) {
                showToast('Invalid invitation link', 'error');
                navigate('/login');
                return;
            }
            try {
                const resultAction = await dispatch(UserInvitationThunks.showByToken(token));
                if (UserInvitationThunks.showByToken.rejected.match(resultAction)) {
                    navigate('/login');
                }
            } catch (error) {
                navigate('/login');
            }
            await dispatch(CredentialThunks.index());
        };
        fetchInvitation();
    }, [dispatch, params.token, navigate]);

    useEffect(() => {
        if (invitation !== null && invitation.status !== 'Pending') {
            showToast('Invitation link has expired', 'error');
            navigate('/login');
        }
        setFormData((prevData) => ({
            ...prevData,
            credentialIds: invitation?.credentialIds ?? []
        }))
    }, [invitation, navigate]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const {password} = formData;

        if (invitation === null || params.token === undefined) {
            showToast('Invalid invitation link', 'error');
            navigate('/login');
            return;
        }
        dispatch(setPassword(password));
        navigate(`/signature/${params.token}`);
    };

    return (
        <div className="flex flex-col justify-between">
            <div className="flex-grow flex flex-col md:pr-20 md:pl-20 pt-20 max-w-120 md:min-w-96 sm:min-w-64">
                {invitation !== null &&
                    <div className="w-80">
                        <div className="w-full text-xl font-semibold tracking-normal text-slate-800">
                            <span>Hello, </span>
                            <span>{invitation?.firstName ?? ""}</span>
                        </div>
                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            Activate your New Hope account by completing the following information
                        </div>
                        <form className="mt-12 needs-validation self-stretch w-full">
                            <div className="flex flex-col space-y-6 w-80">
                                <TextInput
                                    id="email"
                                    type="text"
                                    label="Email"
                                    value={invitation.email}
                                    placeholder={invitation.email}
                                    disabled={true}
                                />

                            </div>
                            <div className="pb-4">
                                <SetupPassword
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </form>
                    </div>
                }
                {invitation === null &&
                    <div className="h-96"><LoadingSpinner/></div>
                }
            </div>
            <footer className="grid grid-rows-2 justify-center w-full ">
                <SubmitButton
                    className={"w-80 py-2.5 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-cyan-400 text-white hover:bg-cyan-500 disabled:pointer-events-none disabled:bg-slate-100  disabled:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"}
                    onClick={handleSubmit}
                    label={<>{'Continue'} <ArrowRightIcon/></>}
                    disabled={status === 'loading' || !isFormValid}
                />
                <div className="flex justify-center items-center ">
                    <div className="flex items-center gap-3">
                        <div className="w-2 h-2 bg-cyan-400 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default AcceptInvitationPage;
