import React from 'react';
import { TimeInputElementClass } from 'types/documentTemplateElement';
import { ElementProps } from 'types/documentTemplateElement';
import TextInput from "../../inputs/TextInput";
import TimeInput from "../../inputs/TimeInput";

const TimeInputElementComponent: React.FC<ElementProps<TimeInputElementClass>> = ({ element, updateElement }) => {
  return (
      <div className="w-full m-8">
        <TimeInput
            id={element.id}
            label={element.properties.showLabel ? element.properties.label : ""}
            value=""
            placeholder={element.properties.placeholder}
            required={element.properties.required}
            onChange={() => {}}
            isClickDisabled={true}
        />
      </div>
  );
};

export default TimeInputElementComponent;
