export interface UserMedicalDocumentInterface {
    id: string;
    name: string;
    agency: string;
    expirationDate: string;
    notes: string;
    remindUser: string;
    status: string;
    attachment?: any;
    deleteAttachment?: boolean;
    previousAttachments?: any[];
}

export class UserMedicalDocument implements UserMedicalDocumentInterface {
    id: string;
    name: string;
    agency: string;
    expirationDate: string;
    notes: string;
    remindUser: string;
    status: any;
    attachment?: any;
    deleteAttachment?: boolean;
    previousAttachments?: any[];

    constructor(data: Partial<UserMedicalDocumentInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.agency = data.agency!;
        this.expirationDate = data.expirationDate!;
        this.notes = data.notes!;
        this.remindUser = data.remindUser!;
        this.status = data.status;
        this.attachment = data.attachment;
        this.deleteAttachment = data.deleteAttachment;
    }

    toJson(): UserMedicalDocumentInterface {
        return {
            id: this.id,
            name: this.name,
            agency: this.agency,
            expirationDate: this.expirationDate,
            notes: this.notes,
            attachment: this.attachment,
            status: this.status?.toLowerCase(),
            remindUser: this.remindUser,
            deleteAttachment: this.deleteAttachment,
            previousAttachments: this.previousAttachments,
        };
    }
}
