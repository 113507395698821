// TableInputProperties.tsx
import React from 'react';
import { TableInputElement } from 'types/documentTemplateElement';
import RadioInputHorizontal from '../../inputs/RadioInputHorizontal';
import TextInput from '../../inputs/TextInput';

interface TableInputPropertiesProps {
    element: TableInputElement;
    updateElement: (updatedElement: TableInputElement) => void;
}

const TableInputProperties: React.FC<TableInputPropertiesProps> = ({
                                                                       element,
                                                                       updateElement,
                                                                   }) => {
    const { label, rows, columns, showLabel, data } = element.properties;

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { id, value, type } = e.target;
        const parsedValue =
            id === 'rows' || id === 'columns' ? parseInt(value, 10) || 0 : value;

        updateElement({
            ...element,
            properties: {
                ...element.properties,
                [id]: parsedValue,
            },
        });
    };

    const handleShowLabelChange = (value: boolean) => {
        updateElement({
            ...element,
            properties: {
                ...element.properties,
                showLabel: value,
            },
        });
    };

    return (
        <div className="space-y-5 w-full">
            <RadioInputHorizontal
                label="Show Label?"
                options={[
                    { id: 'show-label-yes', label: 'Yes', value: true },
                    { id: 'show-label-no', label: 'No', value: false },
                ]}
                selectedValue={showLabel}
                onChange={handleShowLabelChange}
            />

            {showLabel && (
                <TextInput
                    id="label"
                    type="text"
                    label="Table Label"
                    value={label}
                    onChange={handleChange}
                />
            )}

            <div className="flex gap-4">
                <TextInput
                    id="rows"
                    type="number"
                    label="Rows"
                    value={rows.toString()}
                    onChange={handleChange}
                />
                <TextInput
                    id="columns"
                    type="number"
                    label="Columns"
                    value={columns.toString()}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

export default TableInputProperties;
