import React from 'react';
import { RatingInputElementClass } from 'types/documentTemplateElement';
import { ElementProps } from 'types/documentTemplateElement';
import CustomSelect from "../../inputs/CustomSelect";

const RatingInputElementComponent: React.FC<ElementProps<RatingInputElementClass>> = ({ element, updateElement }) => {
  return (
      <div className="w-full m-8">
          <div className="flex flex-col gap-[0.625rem] w-full">
              {element.properties.showLabel && (
                  <label htmlFor="roleId"
                         className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                      {element.properties.label}
                  </label>)}
              <CustomSelect
                    options={Array.from({length: element.properties.maxRating}, (_, i) => i + 1).map((option) => ({label: option, value: option}))}
                  onChange={() => {
                  }}
                  value={element.properties}
              />
          </div>
      </div>
  );
};

export default RatingInputElementComponent;
