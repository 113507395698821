import React from 'react';
import { CurrencyInputElementClass } from 'types/documentTemplateElement';
import { ElementProps } from 'types/documentTemplateElement';
import TextInput from "../../inputs/TextInput";
import CurrencyIcon from "../../../assets/images/icons/CurrencyIcon";

const CurrencyInputElementComponent: React.FC<ElementProps<CurrencyInputElementClass>> = ({ element, updateElement }) => {
  return (
      <div className="w-full m-8">
        <TextInput
            id={element.id}
            type="text"
            icon={<CurrencyIcon/>}
            label={element.properties.showLabel ? element.properties.label : ""}
            value=""
            placeholder={element.properties.placeholder}
            required={element.properties.required}
            onChange={() => {
            }}
        />
      </div>
  );
};

export default CurrencyInputElementComponent;
