import React from 'react';
import {ElementProps, SsnInputElement, SsnInputElementClass} from "types/documentTemplateElement";
import SSNInput from "../../inputs/SSNInput";

interface SsnInputElementProps extends ElementProps<SsnInputElementClass> {
}

const SsnInputElementComponent: React.FC<SsnInputElementProps> = ({element, updateElement}) => {
    return (
        <div className="w-full m-8">
            <SSNInput
                id={element.id}
                label={element.properties.showLabel ? element.properties.label : ""}
                value=""
                onChange={() => {}}
                isClickDisabled={true}
                required={element.properties.required}
            />
        </div>
    );
};

export default SsnInputElementComponent;
