import {
    DocumentElementClass,
    DocumentElement,
    DateInputElement,
    DateInputElementClass,
    YesNoInputElement,
    YesNoInputElementClass,
    PhoneNumberInputElement,
    PhoneNumberInputElementClass,
    MultiLineTextInputElement,
    MultiLineTextInputElementClass,
    TextInputElement,
    TextInputElementClass,
    TextBlockElement,
    TextBlockElementClass,
    DropdownElement,
    DropdownElementClass,
    SsnInputElementClass,
    SsnInputElement,
    EmailInputElement,
    EmailInputElementClass,
    TableInputElementClass,
    DateTimeInputElement,
    DateTimeInputElementClass,
    UrlInputElement,
    UrlInputElementClass,
    AddressInputElementClass,
    AddressInputElement,
    CurrencyInputElementClass,
    CurrencyInputElement,
    RatingInputElementClass,
    RatingInputElement,
    SignatureInputElementClass,
    SignatureInputElement,
    TimeInputElementClass, TimeInputElement
} from "./documentTemplateElement";

export interface DocumentTemplateColumnInterface {
    id?: string;
    element?: DocumentElement;
    order?: number;
    document_template_element_attributes?: DocumentElement;
}

export class DocumentTemplateColumn implements DocumentTemplateColumnInterface {
    id: string;
    order?: number;
    element?: DocumentElementClass;

    constructor(data: Partial<DocumentTemplateColumnInterface>) {
        this.id = data.id!;
        this.order = data.order!;
        if (data.element) {
            this.element = this.createElementInstance(data.element);
        }
    }

    private createElementInstance(elementData: DocumentElement): DocumentElementClass {
        switch (elementData.elementType) {
            case 'Dropdown':
                return new DropdownElementClass(elementData as DropdownElement);
            case 'TextBlock':
                return new TextBlockElementClass(elementData as TextBlockElement);
            case 'TextInput':
                return new TextInputElementClass(elementData as TextInputElement);
            case 'MultiLineTextInput':
                return new MultiLineTextInputElementClass(elementData as MultiLineTextInputElement);
            case 'PhoneNumberInput':
                return new PhoneNumberInputElementClass(elementData as PhoneNumberInputElement);
            case 'YesNoInput':
                return new YesNoInputElementClass(elementData as YesNoInputElement);
            case 'DateInput':
                return new DateInputElementClass(elementData as DateInputElement);
            case 'SsnInput':
                return new SsnInputElementClass(elementData as SsnInputElement);
            case 'EmailInput':
                return new EmailInputElementClass(elementData as EmailInputElement);
            case 'TableInput':
                return new TableInputElementClass(elementData as TableInputElementClass);
            case 'DateTimeInput':
                return new DateTimeInputElementClass(elementData as DateTimeInputElement);
            case "AddressInput":
                return new AddressInputElementClass(elementData as AddressInputElement);
            case "CurrencyInput":
                return new CurrencyInputElementClass(elementData as CurrencyInputElement);
            case "RatingInput":
                return new RatingInputElementClass(elementData as RatingInputElement);
            case "SignatureInput":
                return new SignatureInputElementClass(elementData as SignatureInputElement);
            case "TimeInput":
                return new TimeInputElementClass(elementData as TimeInputElement);
            case "UrlInput":
                return new UrlInputElementClass(elementData as UrlInputElement);
            default:
                throw new Error(`Unsupported element type: ${elementData}`);
        }
    }

    toJson(): DocumentTemplateColumnInterface {
        const idIsNumeric = /^\d+$/.test(this.id);
        return {
            id: idIsNumeric ? this.id : undefined,
            order: this.order,
            document_template_element_attributes: this.element ? this.element.toJson() : undefined,
        };
    }
}
