import React from 'react'
import { TableInputElement } from 'types/documentTemplateElement'

interface TableInputElementComponentProps {
    element: TableInputElement
    updateElement: (updatedElement: TableInputElement) => void
}

const TableInputElementComponent: React.FC<TableInputElementComponentProps> = ({
                                                                                   element,
                                                                                   updateElement
                                                                               }) => {
    const { label, rows, columns, showLabel, data } = element.properties

    function handleCellChange(rowIndex: number, colIndex: number, value: string) {
        const newData = data.map((rowArr) => [...rowArr])
        // Aseguramos que la fila exista
        if (!newData[rowIndex]) {
            newData[rowIndex] = Array(columns).fill('')
        }
        newData[rowIndex][colIndex] = value

        updateElement({
            ...element,
            properties: {
                ...element.properties,
                data: newData
            }
        })
    }

    return (
        <div className="w-full m-8">
            {showLabel && <p className="mb-2 font-semibold">{label}</p>}

            <table className="border-collapse border border-gray-200">
                <tbody>
                {Array.from({ length: rows }).map((_, rowIndex) => {
                    return (
                        <tr key={rowIndex}>
                            {Array.from({ length: columns }).map((_, colIndex) => {
                                const rowArr = data[rowIndex] || []
                                const cellValue = rowArr[colIndex] || ''

                                return (
                                    <td key={colIndex} className="border border-gray-200 p-2">
                                        <input
                                            type="text"
                                            value={cellValue}
                                            onChange={(e) =>
                                                handleCellChange(rowIndex, colIndex, e.target.value)
                                            }
                                            className="border px-2 py-1 w-full"
                                        />
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

export default TableInputElementComponent
